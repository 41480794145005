/**
 * DO NOT use tailwind classes as this is used for both RGB and Sava
 */
import React, { createRef, useState } from 'react';

import amplitude from 'amplitude-js';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';

import Image from '../../components/basic/Image';
import { getXHoursFromNow } from '../../helpers/date-helpers';
import Link from './LinkHelper';

const isNotFirstColumn = (index) => index !== 0;

const navItems = (items, locale) => {
  return items.map((item, i) => {
    const { _path, title } = item;
    const isExternal = /http/.test(_path);
    let Tag = Link;
    let props = {};

    if (!_path) {
      Tag = 'span';
    } else if (isExternal) {
      Tag = 'a';
      props = {
        href: _path,
        target: '_blank',
      };
    } else {
      props = { href: _path };
    }
    props.locale = locale;

    return (
      <li key={i} className="footer__item">
        <Tag {...props}>
          <span
            dangerouslySetInnerHTML={{ __html: title }}
            className="flex items-center"
          />
        </Tag>
      </li>
    );
  });
};

const Dropdown = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="footer__dropdown">
      <div className="footer__dropdown-container footer__item" onClick={toggleDropdown}>
        <div className={`footer__dropdown-button ${isOpen ? 'active' : ''}`}>
          Hiring Guides
        </div>
        <div className={`imagex footer__dropdown-img-container ${isOpen ? 'rotateImg' : ''}`}>
          <img
            alt="Dropdown icon"
            loading="lazy"
            src="https://rippling2.imgix.net/Shape-9.svg"
          />
        </div>
      </div>
      {isOpen && (
        <ul className="footer__menu dropdown__menu">
          {navItems(items)}
        </ul>
      )}
    </div>
  );
};

const columns = (allColumns, locale) => {
  const resultColumns = [];

  const getColumnInner = (column, isntFirst, hasDropdown, isSpecialColumn, isGlobalColumn, locale) => {
    const {
      heading, heading2 = '', items, items2 = [],
    } = column;

    if (isSpecialColumn) {
      return (
        <div>
          <h4>
            <span>{heading}</span>
          </h4>
          <ul className="footer__menu">
            {navItems(items, locale)}
          </ul>
          {heading2 && (
            <>
              <h4 className="second__header">
                <span>{heading2}</span>
              </h4>
              <ul className="footer__menu">
                {navItems(items2, locale)}
              </ul>
            </>
          )}
        </div>
      );
    } else if (isGlobalColumn) {
      const mainItems = items.slice(0, 9);
      const extraItems = items.slice(9);

      return (
        <div>
          <h4>
            <span>{heading}</span>
          </h4>
          <ul className="footer__menu">
            {navItems(mainItems)}
          </ul>
          {extraItems.length > 0 && <Dropdown items={extraItems} />}
        </div>
      );
    } else {
      const mainItems = hasDropdown ? items.slice(0, 9) : items;
      const extraItems = hasDropdown ? items.slice(9) : [];

      return (
        <div>
          <h4>
            <span>{heading}</span>
          </h4>
          <ul className="footer__menu">
            {navItems(mainItems, locale)}
          </ul>
          {hasDropdown && extraItems.length > 0 && <Dropdown items={extraItems} />}
        </div>
      );
    }
  };

  for (let i = 0; i < allColumns.length; i++) {
    const column = allColumns[i];
    const notFirst = isNotFirstColumn(i);

    if (column.heading === 'Legal') {
      const licensesColumn = allColumns.find((col) => col.heading === 'Licenses');
      if (licensesColumn) {
        const combinedColumn = {
          heading: 'Legal',
          heading2: 'Licenses',
          items: column.items,
          items2: licensesColumn.items,
        };
        resultColumns.push(<div className="footer__column column">
          {getColumnInner(combinedColumn, notFirst, false, true, false)}
        </div>);
        i++;
      } else {
        resultColumns.push(<div className="footer__column column">
          {getColumnInner(column, notFirst, false, false, false)}
        </div>);
      }
    } else if (column.heading === 'Global') {
      resultColumns.push(<div className="footer__column column">
        {getColumnInner(column, notFirst, false, false, true)}
      </div>);
    } else if (column.heading !== 'Licenses') {
      const hasDropdown = i === 5;
      const isSpecialColumn = i === 8;

      resultColumns.push(<div className="footer__column column">
        {getColumnInner(column, notFirst, hasDropdown, isSpecialColumn, false, locale)}
      </div>);
    }
  }

  return resultColumns;
};

const socialIcons = () => [
  <a
    key="si-fb"
    href="https://www.facebook.com/ripplingapp/"
    rel="noopener noreferrer"
    target="_blank"
    title="Like Rippling on Facebook"
  >
    <Image
      attributes={{
        className: 's-footer-fb',
        mediaURL: 'https://rippling2.imgix.net/footer-social-facebook.svg',
        nonResponsive: true,
      }}
    />
  </a>,
  <a
    key="si-li"
    href="https://www.linkedin.com/company/rippling"
    rel="noopener noreferrer"
    target="_blank"
    title="Follow Rippling on LinkedIn"
  >
    <Image
      attributes={{
        className: 's-footer-lin',
        mediaURL: 'https://rippling2.imgix.net/footer-social-linkedin.svg',
        nonResponsive: true,
      }}
    />
  </a>,
  <a
    key="si-tw"
    href="https://x.com/rippling"
    rel="noopener noreferrer"
    target="_blank"
    title="Follow Rippling on X"
  >
    <Image
      attributes={{
        className: 's-footer-tw',
        mediaURL: 'https://rippling2.imgix.net/x-social-icon.svg',
        nonResponsive: true,
      }}
    />
  </a>,
];

const Footer = (data) => {
  const { menu, options } = data;
  const field = createRef();
  const router = useRouter();
  const locale = router.locale;
  const strings = menu?.strings || {};
  if (options === 'No Footer') {
    return null;
  }

  const handleClick = () => {
    const pathname = window.location.pathname;
    const cookies = new Cookies();

    cookies.set('user_email', field.current.value, {
      domain: window.Location.hostname,
      expires: getXHoursFromNow(1),
      path: '/',
    });

    amplitude
      .getInstance()
      .logEvent('Click - Multistep Form', { URL: pathname });

    window.dataLayer.push({
      event: 'multistep_form_start',
      formCategory: 'simple',
      formType: 'footer',
    });

    let redirectTo = '/request-demo';
    if ([
      '/peo',
      '/peo-02',
      '/peo-03',
    ].includes(pathname)) {
      redirectTo = '/peo-request-demo';
    } else if (['/accountants', '/accountants-02'].includes(pathname)) {
      redirectTo = '/accountants-demo-request';
    } else if (['/brokers', '/brokers-02'].includes(pathname)) {
      redirectTo = '/brokers-demo-request';
    }

    router.push(redirectTo);
  };

  return (
    <footer className="footer">
      <div className="footer_top">
        <div className="grid footer__row row">
          <div className="footer__columns">{columns(menu.columns, locale)}</div>
        </div>
        <div className="grid footer__bottom row">
          <div>
            <div className="form-blurb">
              <h2>{strings.readyToSee}</h2>
              <p>{strings.freeDemo}</p>
            </div>
            <div className="form-section">
              <div className="form-container">
                <input
                  ref={field}
                  className="field"
                  placeholder={strings.enterYourEmail}
                  type="text"
                />
                <a className="btn" onClick={handleClick}>
                  {strings.seeRippling}
                </a>
              </div>
              <p
                dangerouslySetInnerHTML={{ __html: strings.disclaimerText }}
                className="footer__privacy"
              />
              <div className="social">{socialIcons()}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__logo">
        <Image
          attributes={{
            mediaURL: 'https://rippling2.imgix.net/wordmark-1.svg',
            nonResponsive: true,
          }}
        />
        <p className="footer__item--legal is-style-overline">
          © {new Date().getFullYear()} Rippling People Center Inc.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
