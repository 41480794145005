/**
 * DO NOT use tailwind classes as this is used for both RGB and Sava
 */
import React, {
  useEffect, useMemo, useState
} from 'react';

import clsx from 'clsx';
import { useRouter, withRouter } from 'next/router';

import Image from '../../components/basic/Image';
import LanguageSelector from './LanguageSelector';
import Link from './LinkHelper';

const ITHeader = (props) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isOpened, setIsOpened] = useState(false);

  // const [isDesktop, setIsDesktop] = useState();
  // const headerNav = useRef();
  const router = useRouter();

  const {
    hasDropdownItems, isMiniItSite, options, scrolled,
  } = props;

  const {
    hasHeader,
    hasNav,
  } = useMemo(() => {
    const getValueFromArray = (value) => {
      return value.includes(false);
    };

    let optionHasHeader = true;
    let optionHasNav = true;

    if (options.header !== undefined) {
      if (Array.isArray(options.header)) {
        optionHasHeader = !getValueFromArray(options.header);
      } else {
        optionHasHeader = !!options.header;
      }
    }

    if (options.nav !== undefined) {
      if (Array.isArray(options.nav)) {
        optionHasNav = !getValueFromArray(options.nav);
      } else {
        optionHasNav = !!options.nav;
      }
    }

    return {
      hasHeader: optionHasHeader,
      hasNav: optionHasNav,
    };
  }, [options]);

  useEffect(() => {
    const handleRouteChange = () => {
      document.body.classList.remove('xsx:overflow-hidden');
      setActiveIndex(null);
    };
    if (router) {
      router.events.on('routeChangeStart', handleRouteChange);

      return () => {
        router.events.off('routeChangeStart', handleRouteChange);
      };
    }

    // No need for a return statement here, as it's already covered by the early exit
  }, [router]);

  useEffect(() => {
    // window.addEventListener('scroll', onScroll);
    window.addEventListener('click', closeNavDropDown);
  }, [activeIndex]);

  // const updatePredicate = () => {
  //   if (window?.innerWidth) {
  //     let innerSlideIndexTemp = null;
  //     if (window.innerWidth >= 1280) {
  //       innerSlideIndexTemp = 1;
  //     } else if (isOpened) {
  //       innerSlideIndexTemp = innerSlideIndex;
  //     }

  //     setIsDesktop(window.innerWidth >= 1280);
  //     setInnerSlideIndex(innerSlideIndexTemp);
  //   }
  // };

  // const throttledUpdatePredicate = throttle(updatePredicate);

  const logo = () => {
    if (isMiniItSite) {
      return (
        <Link
          anchorProps={{ title: 'Rippling IT' }}
          className="header__logo"
          href="/rippling-it"
          onClick={closeMobileNav}
        >
          <svg
            fill="none"
            height="18"
            width="127"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.878 4.99C2.878 3.01 1.875 1.374 0 0h4.358a6.26 6.26 0 012.467 4.99 6.26 6.26 0 01-2.467 4.99c1.415.59 2.22 2.03 2.22 4.091v3.927H2.632v-3.927c0-1.963-.937-3.337-2.631-4.09 1.875-1.375 2.878-3.01 2.878-4.99zm8.552 0c0-1.98-1.003-3.616-2.878-4.99h4.359a6.26 6.26 0 012.466 4.99 6.26 6.26 0 01-2.466 4.99c1.414.59 2.22 2.03 2.22 4.091v3.927h-3.947v-3.927c0-1.963-.938-3.337-2.632-4.09 1.875-1.375 2.878-3.01 2.878-4.99zm8.554 0c0-1.98-1.003-3.616-2.878-4.99h4.358a6.26 6.26 0 012.467 4.99 6.26 6.26 0 01-2.467 4.99c1.415.59 2.22 2.03 2.22 4.091v3.927h-3.947v-3.927c0-1.963-.937-3.337-2.631-4.09 1.875-1.375 2.878-3.01 2.878-4.99zM35.952 14.728H32.96V3.272h7.352c3.536 0 5.279 1.31 5.279 3.453 0 1.456-.855 2.552-2.45 3.109 1.644.245 2.384 1.112 2.384 2.667v2.225h-3.026v-2.094c0-1.31-.658-1.833-2.385-1.833h-4.16v3.929zm4.194-9.787h-4.194v4.19h4.161c1.513 0 2.45-.835 2.45-2.144 0-1.293-.87-2.046-2.417-2.046zM50.787 3.272h-2.993v11.456h2.993V3.272zM59.964 10.995h-3.651v3.731h-2.994V3.272h6.71c3.536 0 5.395 1.473 5.395 3.83 0 2.437-1.891 3.893-5.46 3.893zm-.066-6.054h-3.585v4.385h3.552c1.546 0 2.5-.785 2.5-2.208 0-1.391-.954-2.177-2.467-2.177zM73.845 10.995h-3.651v3.731H67.2V3.272h6.71c3.536 0 5.395 1.473 5.395 3.83 0 2.437-1.892 3.893-5.46 3.893zm-.066-6.054h-3.585v4.385h3.552c1.546 0 2.5-.785 2.5-2.208 0-1.391-.954-2.177-2.467-2.177zM84.074 3.272v9.752h7.467v1.704h-10.46V3.272h2.993zM96.407 3.272h-2.993v11.456h2.993V3.272zM100.914 7.101v7.627H98.94V3.272h2.239l8.157 7.625V3.272h1.974v11.456h-2.237L100.914 7.1zM121.08 4.614c-2.846 0-4.704 1.8-4.704 4.483 0 2.65 1.776 4.287 4.539 4.287h.197c.938 0 1.99-.197 2.961-.507V9.784h-4.835V8.148H127v5.383c-1.611.835-4.046 1.49-6.019 1.49h-.263c-4.54 0-7.467-2.39-7.467-5.859 0-3.436 3.01-6.184 7.631-6.184h.263c1.925 0 4.128.605 5.757 1.554l-.856 1.39c-1.463-.801-3.207-1.308-4.769-1.308h-.197z" />
          </svg>
          <svg
            className="header__logo_IT"
            fill="none"
            height="16"
            viewBox="0 0 18 16"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0.26 13V0.399999H3.194V13H0.26ZM17.6412 2.128H12.7992V13H9.86522V2.128H5.00522V0.399999H17.6412V2.128Z" fill="#C4A7B6" />
          </svg>

        </Link>
      );
    } else {
      return (
        <Link
          anchorProps={{ title: 'Rippling IT' }}
          className="header__logo"
          href="/rippling-it"
          onClick={closeMobileNav}
        >
          <div className="header__logo_animated">
            <svg
              className="header__logo_Rippling"
              fill="#FFF"
              height="18"
              width="127"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2.878 4.99C2.878 3.01 1.875 1.374 0 0h4.358a6.26 6.26 0 012.467 4.99 6.26 6.26 0 01-2.467 4.99c1.415.59 2.22 2.03 2.22 4.091v3.927H2.632v-3.927c0-1.963-.937-3.337-2.631-4.09 1.875-1.375 2.878-3.01 2.878-4.99zm8.552 0c0-1.98-1.003-3.616-2.878-4.99h4.359a6.26 6.26 0 012.466 4.99 6.26 6.26 0 01-2.466 4.99c1.414.59 2.22 2.03 2.22 4.091v3.927h-3.947v-3.927c0-1.963-.938-3.337-2.632-4.09 1.875-1.375 2.878-3.01 2.878-4.99zm8.554 0c0-1.98-1.003-3.616-2.878-4.99h4.358a6.26 6.26 0 012.467 4.99 6.26 6.26 0 01-2.467 4.99c1.415.59 2.22 2.03 2.22 4.091v3.927h-3.947v-3.927c0-1.963-.937-3.337-2.631-4.09 1.875-1.375 2.878-3.01 2.878-4.99zM35.952 14.728H32.96V3.272h7.352c3.536 0 5.279 1.31 5.279 3.453 0 1.456-.855 2.552-2.45 3.109 1.644.245 2.384 1.112 2.384 2.667v2.225h-3.026v-2.094c0-1.31-.658-1.833-2.385-1.833h-4.16v3.929zm4.194-9.787h-4.194v4.19h4.161c1.513 0 2.45-.835 2.45-2.144 0-1.293-.87-2.046-2.417-2.046zM50.787 3.272h-2.993v11.456h2.993V3.272zM59.964 10.995h-3.651v3.731h-2.994V3.272h6.71c3.536 0 5.395 1.473 5.395 3.83 0 2.437-1.891 3.893-5.46 3.893zm-.066-6.054h-3.585v4.385h3.552c1.546 0 2.5-.785 2.5-2.208 0-1.391-.954-2.177-2.467-2.177zM73.845 10.995h-3.651v3.731H67.2V3.272h6.71c3.536 0 5.395 1.473 5.395 3.83 0 2.437-1.892 3.893-5.46 3.893zm-.066-6.054h-3.585v4.385h3.552c1.546 0 2.5-.785 2.5-2.208 0-1.391-.954-2.177-2.467-2.177zM84.074 3.272v9.752h7.467v1.704h-10.46V3.272h2.993zM96.407 3.272h-2.993v11.456h2.993V3.272zM100.914 7.101v7.627H98.94V3.272h2.239l8.157 7.625V3.272h1.974v11.456h-2.237L100.914 7.1zM121.08 4.614c-2.846 0-4.704 1.8-4.704 4.483 0 2.65 1.776 4.287 4.539 4.287h.197c.938 0 1.99-.197 2.961-.507V9.784h-4.835V8.148H127v5.383c-1.611.835-4.046 1.49-6.019 1.49h-.263c-4.54 0-7.467-2.39-7.467-5.859 0-3.436 3.01-6.184 7.631-6.184h.263c1.925 0 4.128.605 5.757 1.554l-.856 1.39c-1.463-.801-3.207-1.308-4.769-1.308h-.197z" />
            </svg>
            <svg
              className="header__logo_IT"
              fill="none"
              height="16"
              viewBox="0 0 18 16"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.26 13V0.399999H3.194V13H0.26ZM17.6412 2.128H12.7992V13H9.86522V2.128H5.00522V0.399999H17.6412V2.128Z" fill="#fff" />
            </svg>
          </div>
        </Link>
      );
    }
  };

  const left = () => {
    return (
      <div className={clsx('header__left  -is-paidIT-site')}>
        {logo()}
      </div>
    );
  };

  const right = (props) => {
    const { menu, router } = props;

    return (
      <div
        className={clsx(
          'header__right ',
          isMiniItSite ? ' -is-itsite' : '  -is-paidIT-site'
        )}
      >
        {hasNav && nav()}
        <div className="header__rightEnd">
          {isMiniItSite && (
            <LanguageSelector
              isMiniItSite={props.isMiniItSite}
              isMiniSite={props.isMiniSite}
              languages={menu.languages}
              miniSiteLanguages={menu.miniSiteLanguages}
              pageLocale={props.pageLocale}
              router={router}
              strings={menu.strings}
            />
          )}
          {buttons()}
        </div>
      </div>
    );
  };

  const buttons = () => {
    const trialButton = (
      <Link href="/it-trial" onClick={closeMobileNav}>
        {strings.startFreeTrial}
      </Link>
    );

    return (
      <div className={clsx('header__buttons')}>
        <div className={clsx('btn is-style-secondary')}>
          {trialButton}
        </div>
      </div>
    );
  };

  const nav = () => {
    const { menu } = props;

    // TO DO: Deprecate  Use graphql to query only top level links
    const menuItems = typeof menu === 'string' ? JSON.parse(menu) : menu;

    const menus = () => {
      return [
        Menu(menuItems.paidITHeros, null, 0),
        Menu(menuItems.paidITWhyRippling, null, 1),
        Menu(menuItems.paidITPlatform, null, 2),
        Menu(menuItems.paidITProducts, null, 3),
        Menu(menuItems.paidITSolutions, null, 4),
        Menu(menuItems.paidITResources, null, 5),
      ];
    };

    return (
      <ul className="header__nav">
        {menus()}
        <div
          className="it-overlay"
          style={{
            opacity:
              activeIndex === null
                ? 0
                : 1,
          }}
        />
      </ul>
    );
  };

  const Menu = (menu, key, i) => {
    const heading = menu?.heading || null;
    key = menu?._key || key;
    const hasDropdown = hasDropdownItems.has(key);

    let nameClass = '-language-selector-menu';
    if (key !== null) {
      nameClass = `-${key.toLowerCase()}-menu`;
    } else if (heading !== null) {
      nameClass = `-${heading.replace(' ', '-').toLowerCase()}-menu`;
    }

    return (
      <div
        key={`header__menu-${nameClass}-${i}`}
        className={clsx('header__menu header__menu-top-menu', nameClass)}
      >
        <li>
          {hasDropdown ? (
            <strong
              className="header__dropdownTrigger header__dropdownTriggerCursor"
              data-active={activeIndex === i || undefined}
              onClick={accoridonOpen.bind(this, i)}
              role="button"
              tabIndex={i}
            >
              <span>{heading}</span>
              <div className="imagex header-chevron-icon">
                <svg
                  fill="none"
                  height="20"
                  viewBox="0 0 20 20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.22498 8.77479L7.10914 7.89062L10.0008 10.7823L12.8925 7.89062L13.7766 8.77479L10.0016 12.5498L6.22664 8.77479H6.22498Z"
                    fill="#fff"
                  />
                </svg>
              </div>
            </strong>
          ) : (
            <Link
              anchorProps={{ onClick: closeMobileNav }}
              href={menu?._path || menu?.url}
              id={'0' + (i + 1)}
            >
              <strong className="header__dropdownTrigger">
                <span>{heading}</span>
              </strong>
            </Link>
          )}
        </li>
        {hasDropdown ? dropdown(menu, key, i) : ''}
      </div>
    );
  };

  const dropdown = (menu, key, i) => {
    const nameClass = key ? ` -${key.toLowerCase()}-dropdown` : '';
    const dropdowns = {
      languages: () => (
        <LanguageSelector
          isMobile={true}
          languages={menu}
          pageLocale={props.pageLocale}
          router={props.router}
          strings={strings}
        />
      ),
      paidITPlatform: paidITPlatformDropDown,
      paidITProducts: paidITProductsDropDown,
      paidITResources: paidITResourcesDropDown,
      paidITSolutions: paidITSolutionsDropDown,
    };

    return (
      <div
        className={clsx(
          'dropdown -disabled',
          nameClass
        )}
        data-active={activeIndex === i || undefined}
      >
        {dropdowns[key](menu)}
      </div>
    );
  };

  const paidITProductsDropDown = (menu) => {
    const paidProductsID = '04';
    var paidProductsItemCount = 0; //running count

    return (
      <div className="dropdown__paidITProducts">
        <div className="dropdown__wrapper">
          <div className="dropdown__submenu">
            {menu.items.map(({
              _image, _path, heading, subheading,
            }, i) => {
              paidProductsItemCount++;
              var paidProductsItemID = paidProductsID + String(paidProductsItemCount).padStart(4, '0');

              return (
                <Link
                  key={`dropdown__link-${i}`}
                  className=" group"
                  href={_path}
                  id={paidProductsItemID}
                  onClick={closeMobileNav}
                >
                  <div className="dropdown__linkIcon">
                    <Image
                      attributes={{
                        mediaURL: _image,
                        nonResponsive: true,
                      }}
                    />
                  </div>
                  <div className="dropdown__linkIcon__link">
                    <p className="dropdown__link-title">{heading}</p>
                    <p className="dropdown__link-subtext">{subheading}</p>
                  </div>
                  <svg
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 13.3672L8.30489 14.1667L12.5 10L8.30489 5.83333L7.5 6.63276L10.891 10.0007L7.5 13.3687V13.3672Z"
                      fill="#402530"
                      fillOpacity="0.3"
                    />
                  </svg>
                </Link>
              );
            })
            }
          </div>
        </div>
      </div>
    );
  };

  const paidITPlatformDropDown = (menu) => {
    const paidITSolutionsID = '03';

    return (
      <div className="dropdown__paidITPlatform">
        <div className="dropdown__wrapper">
          <div className="dropdown__submenu">
            {menu.items.map(({ _path, heading }, i) => {
              var itemID = i + 1;
              var paidITSolutionsItemID = paidITSolutionsID + String(itemID).padStart(4, '0');

              return (
                <Link
                  key={`dropdown__link-${i}`}
                  className="link group"
                  href={_path}
                  id={paidITSolutionsItemID}
                  onClick={closeMobileNav}
                >
                  <div className="dropdown__linkIcon__link">
                    <p className="dropdown__link-title">{heading}</p>
                  </div>
                  <svg
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 13.3672L8.30489 14.1667L12.5 10L8.30489 5.83333L7.5 6.63276L10.891 10.0007L7.5 13.3687V13.3672Z"
                      fill="#402530"
                      fillOpacity="0.3"
                    />
                  </svg>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const paidITSolutionsDropDown = (menu) => {
    const paidITSolutionsID = '05';

    return (
      <div className="dropdown__paidITSolutions">
        <div className="dropdown__wrapper">
          <div className="dropdown__submenu">
            {menu.itemsList.map(({
              _image, _path, heading,
            }, i) => {
              var itemID = i + 1;
              var paidITSolutionsItemID = paidITSolutionsID + String(itemID).padStart(4, '0');

              return (
                <Link
                  key={`dropdown__link-${i}`}
                  className="link"
                  href={_path}
                  id={paidITSolutionsItemID}
                  onClick={closeMobileNav}
                >
                  <div className="dropdown__iconSquare">
                    <Image
                      attributes={{
                        mediaURL: `${_image}`,
                        nonResponsive: true,
                      }}
                    />
                  </div>
                  <div className="dropdown__linkIcon__link">
                    <p className="dropdown__link-title">{heading}</p>
                  </div>
                  <svg
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 13.3672L8.30489 14.1667L12.5 10L8.30489 5.83333L7.5 6.63276L10.891 10.0007L7.5 13.3687V13.3672Z"
                      fill="#402530"
                      fillOpacity="0.3"
                    />
                  </svg>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const paidITResourcesDropDown = (menu) => {
    const paidResourcesID = '05';
    var paidResourcesItemCount = 0; //running count

    return (
      <div className="dropdown__paidITResources">
        <div className="dropdown__wrapper">
          <div className="dropdown__submenu tour">
            <p className="dropdown__submenu__title">{menu.tourVideo.heading}</p>
            {menu.tourVideo.items.map(({
              _path, cta, heading, image,
            }, i) => {
              paidResourcesItemCount++;
              var paidResourcesItemID = paidResourcesID + String(paidResourcesItemCount).padStart(4, '0');

              return (
                <Link
                  key={`dropdown__link-${i}`}
                  className="dropdown__submenu__link"
                  href={_path}
                  id={paidResourcesItemID}
                  onClick={closeMobileNav}
                >
                  <div className="dropdown__submenu__image">
                    <Image
                      attributes={{
                        className: 'tour-video',
                        mediaURL: image,
                        nonResponsive: true,
                      }}
                    />
                    <Image
                      attributes={{
                        className: 'play-btn',
                        mediaURL: 'https://rippling2.imgix.net/Frame-427321256.svg',
                        nonResponsive: true,
                      }}
                    />
                  </div>
                  <div className="dropdown__linkIcon__link">
                    <p className="dropdown__link-title">{heading}</p>
                    <p className="dropdown__highlightLearnMore line-draw">{cta}</p>
                  </div>
                </Link>
              );
            })}
          </div>
          <div className="dropdown__submenu webinar">
            <p className="dropdown__submenu__title">{menu.webinars.heading}</p>
            {menu.webinars.items.map(({ _path, heading }, i) => {
              paidResourcesItemCount++;
              var paidResourcesItemID = paidResourcesID + String(paidResourcesItemCount).padStart(4, '0');

              return (
                <Link
                  key={`dropdown__link-${i}`}
                  className="link-box"
                  href={_path}
                  id={paidResourcesItemID}
                  onClick={closeMobileNav}
                >
                  <div className="dropdown__linkIcon__link">
                    <div className="dropdown__linkIcon__link__image h-48 w-48">
                      <Image
                        attributes={{
                          className: 'w-20 h-20',
                          mediaURL: 'https://rippling2.imgix.net/play-3.svg',
                          nonResponsive: true,
                        }}
                      />
                    </div>
                    <p className="dropdown__link-title">{heading}</p>
                  </div>
                </Link>
              );
            })}
          </div>
          <div className="dropdown__submenu blog">
            <p className="dropdown__submenu__title">{menu.blog.heading}</p>
            <div className="blogs">
              {menu.blog.items.map(({
                _image, _path, heading,
              }) => {
                paidResourcesItemCount++;
                var paidProductsItemID = paidResourcesID + String(paidResourcesItemCount).padStart(4, '0');

                return (
                  <Link
                    key={heading}
                    className="dropdown__bannerLink line-card"
                    href={_path}
                    id={paidProductsItemID}
                    onClick={closeMobileNav}
                  >
                    <div className="resources__card">
                      <div className="dropdown__customersTestimonialImage">
                        <Image
                          attributes={{
                            mediaURL: _image,
                            nonResponsive: true,
                          }}
                        />
                      </div>
                      <div className="dropdown__customersTestimonialCopy">
                        <p className="dropdown__bannerSubheading">
                          {heading}
                        </p>
                        <p className="dropdown__bannerLearnMore line-draw">
                          {strings.readStory}
                        </p>
                      </div>
                    </div>
                  </Link>

                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const mobileSpot = () => {
    if (hasNav) {
      return (
        <div
          className="carrot-arrow"
          onClick={toggleMobileMenu}
          role="button"
          tabIndex={0}
        >
          <svg
            fill="none"
            height="6"
            viewBox="0 0 10 6"
            width="10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0.959314 0L0 0.965863L5 6L10 0.965863L9.04069 0L4.9991 4.06919L0.957505 0H0.959314Z" fill="white" />
          </svg>
        </div>
      );
    } else {
      return buttons(true);
    }
  };

  const closeNavDropDown = (e) => {
    const targetElement = e.target;
    const headerDropdown = document.querySelector('.header__subbar');
    if (headerDropdown && !headerDropdown.contains(targetElement)) {
      setActiveIndex(null);
    }
  };

  const toggleMobileMenu = () => {
    document.body.classList.toggle('xsx:overflow-hidden');
    setIsOpened(!isOpened);

    // setInnerSlideIndex(0);
    setActiveIndex(null);
  };

  const accoridonOpen = (i) => {
    setActiveIndex(activeIndex === i ? null : i);
  };

  const closeMobileNav = () => {
    // TODO Find more graceful way to handle bug when mobile header is scrolled
    const mobileWrapper = document.querySelector('.header__mobileWrapper');
    if (mobileWrapper) {
      mobileWrapper.scrollTop = 0;
      setIsOpened(false);
    }
  };

  const isOpenedClass = isOpened ? ' -is-opened' : '';
  const scrolledClass = scrolled ? ' -scroll' : '';

  const strings = props.menu?.strings || {};

  if (!hasHeader) {
    return null;
  }

  return (
    <header
      className={`header header__subbar ${scrolledClass} ${isMiniItSite ? '  -is-paidit' : ' -is-it'} ${isOpenedClass} ${scrolledClass}`}
    >
      <div className="header__mobileWrapper">
        <div className="header__container">
          <div className="header__wrapper">
            <div className="header__wrapper_top">
              {left()}
              {mobileSpot()}
            </div>
            {right(props)}
          </div>
        </div>

      </div>
    </header>
  );
};

export default withRouter(ITHeader);
